import React, { useState } from 'react';
import { Input, Button, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';

const FilterHandler = ({ dataIndex, confirm, clearFilters,  onReset }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchValue, setSearchValue] = useState(searchParams.get(dataIndex) || '');

  const updateQueryParams = (key, value) => {
    if (value) {
      searchParams.set(key, value);
    } else {
      searchParams.delete(key);
    }
    setSearchParams(searchParams);
  };

  // Handle search
  const handleSearch = () => {
    confirm();
    updateQueryParams(dataIndex, searchValue);
  };

  // Handle reset
  const handleReset = () => {
    clearFilters();
    updateQueryParams(dataIndex, '');
    setSearchValue('');
    onReset && onReset();
  };

  return (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={`Search ${dataIndex}`}
        value={searchValue}
        onChange={(e) => {
          setSearchValue(e.target.value);
        }}
        onPressEnter={handleSearch}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={handleSearch}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button onClick={handleReset} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </Space>
    </div>
  );
};

export default FilterHandler;
