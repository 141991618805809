/* eslint-disable no-unused-vars */
import './Users.css'
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button, Input, Modal, Spin } from 'antd';
import { ColumnHeightOutlined, FullscreenOutlined, ReloadOutlined, UserAddOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Fullscreen from "fullscreen-react";
import { Outlet, useParams } from 'react-router-dom';
import CommonLayout from "components/layout/Layout";
import { TextMessage, SECONDS } from 'utils/constants';
import { updateUserBySearch, getAllTeamUsers, getUnassignUsers, assignUserToTeam, unassignUserFromTeam } from 'actions/users/index'
import { setPage } from "actions/app";
import { getTeamById, showHideUnassignButton } from "actions/teams/index"
import CustomTable from "components/common/table/TableNew";
import { UNASSIGN_USER_LIST_COLUMNS } from 'utils/column';

const { Search } = Input;

const SharedTeamDetailLayout = () => {
  const { teamId } = useParams()
  const { currentTeam, showUnassignButton, newSelectedRowKeys } = useSelector((state) => state.teams)
  const [selectedUnassignUsers, setSelectedUnassignUsers] = useState(undefined);
  const { unassignUsers, unassignUsersTotal } = useSelector((state) => state.users)
  const [currPage, setCurrPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [tableLoading, setTableLoading] = useState(false);


  const dispatch = useDispatch()
  const users = useSelector((state) => state.users.users)
  const [isEnter, setIsEnter] = useState(false);

  const [modalOpen, setModalOpen] = useState(false)
  const [currentAction, setCurrentAction] = useState('')
  const [currentRecord, setCurrentRecord] = useState({
    userId: "",
    firstName: "",
    lastName: "",
    email: "",
    role: '',
  })
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [role, setRole] = useState('Merchant')

  const [firstNameError, setFirstNameError] = useState('')
  const [lastNameError, setLastNameError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [okBtnText, setOkBtnText] = useState('')
  const [showAddAlert, setShowAddAlert] = useState(false)
  const [addAlertText, setAddAlertText] = useState('')
  const [addAlertType, setAddAlertType] = useState('')
  const [loading, setLoading] = useState(false)
  const [tableSize, setTableSize] = useState('default')
  const [tableClicked, setTableClicked] = useState(0)
  const [searchedText, setSearchedText] = useState('')
  const [userSearchedText, setUserSearchedText] = useState('')
  const [loadingState, setLoadingState] = useState(false)
  const [reloadingState, setReLoadingState] = useState(false)

  useEffect(() => {
    if (showAddAlert) {
      setTimeout(() => {
        setShowAddAlert(false)
      }, SECONDS);
    }
  }, [showAddAlert])

  useEffect(() => {
    dispatch(setPage('Teams'))
    dispatch(getTeamById(teamId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId])

  useEffect(() => {
    if (searchedText === '') {
      dispatch(updateUserBySearch([]))
    }
  }, [searchedText, dispatch])

  const handleUserSearch = (e) => {
    setUserSearchedText(e.target.value);
  };

  const onUserSearch = (value) => {
    if (value) {
      getData({ page: 1 }, false)
    } else {
      setUserSearchedText('');
      getData({ page: 1, search: '' }, false)
    }
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedUnassignUsers(newSelectedRowKeys);
  };

  const renderTag = () => {
    return (
      <div>
        <div >
          <Search placeholder="Search user" enterButton allowClear
            onSearch={onUserSearch} className='userErrorBtn mb-25' onChange={handleUserSearch}
          />
          <span className='labelField'>Recommendations</span>
          <div className='w-100 margin-top-8'>
            <CustomTable
              rowSelectionType='checkbox'
              onSelectRows={onSelectChange}
              showQuickJumper={false}
              scroll={{ y: 240 }}
              columns={UNASSIGN_USER_LIST_COLUMNS}
              rows={unassignUsers}
              rowKey="_id"
              total={unassignUsersTotal}
              isPromoView={false}
              isLoading={tableLoading}
              onChange={async (
                { current, pageSize },
                filter = {},
                { field, order, columnKey }
              ) => {
                const params = {
                  page: current,
                  perPage: pageSize,
                  sortBy: order && columnKey,
                  orderBy: order,
                };
                setCurrPage(current);
                setPageSize(pageSize);
                setTableLoading(true);
                getData(params, false);
              }}
            />
          </div>
        </div>

      </div>
    );
  };

  const handleUnassignUsers = async (val) => {
    if (newSelectedRowKeys.length) {
      const response = await dispatch(unassignUserFromTeam(newSelectedRowKeys, teamId, val));
      if (response.error === undefined) {
        dispatch(showHideUnassignButton(false, []))
        handleReload();
        setLoading(false)
        setShowAddAlert(true)
        setAddAlertText(TextMessage.USER_UNASSIGN_TEXT)
        setAddAlertType('success')
        resetValues()
      } else {
        deleteUser(response?.error?.response?.data?.message);
      }
    }
  }

  const deleteUser = (val) => {
    Modal.confirm({
      title: val,
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => handleUnassignUsers('warning'),
      okType: 'primary',
    });
  }

  const handleAdd = async () => {
    const params = {
      page: 1,
      perPage: 10
    }
    await dispatch(getUnassignUsers(params))
    setModalOpen(true)
    setCurrentAction('Add User')
    setOkBtnText('Add User')
  }

  const getData = async (params = {}, isLoading = true) => {
    if (params?.search !== '' && userSearchedText) {
      params.search = userSearchedText;
    }
    if (isLoading) {
      setLoadingState(true);
    }
    const res = await dispatch(getUnassignUsers(params));
    if (res) {
      setLoadingState(false);
      setTableLoading(false);
    }
  };

  const handleCancel = () => {
    setModalOpen(false)
    setCurrentAction('')
    setFirstName('')
    setLastName('')
    setEmail('')
    setFirstNameError('')
    setLastNameError('')
    setEmailError('')
    setOkBtnText('')
    setCurrentRecord({
      firstName: "",
      lastName: "",
      email: "",
      role: '',
    })
  }

  const resetValues = () => {
    setModalOpen(false)
    setCurrentAction('')
    setFirstName('')
    setLastName('')
    setEmail('')
    setFirstNameError('')
    setLastNameError('')
    setEmailError('')
    setOkBtnText('')
  }

  const handleSubmit = async (e) => {
    e.preventDefault()


    if (currentAction === 'Add User' && selectedUnassignUsers?.length) {
      setLoading(true)

      const response = await dispatch(assignUserToTeam(selectedUnassignUsers, teamId))

      if (response.error === undefined) {
        handleReload();
        setLoading(false)
        setShowAddAlert(true)
        setAddAlertText(TextMessage.USER_ASSIGNED_TEXT)
        setAddAlertType('success')
        resetValues()
      } else {
        setLoading(false)
        setShowAddAlert(true)
        setAddAlertText(TextMessage.ERROR_TEXT)
        setAddAlertType('error')
        resetValues()
      }

    }

  }

  const handleAddAlertClose = () => {
    setShowAddAlert(false)
    setCurrentAction('')
  }

  const onSearch = async (value) => {
    const searchedUser = users.filter(item => (item?.fullName?.toLowerCase().trim().includes(value.toLowerCase().trim())) ||
      (item?.email?.toLowerCase().trim().includes(value.toLowerCase().trim())))
    dispatch(updateUserBySearch(searchedUser))
  }

  const handleReload = async () => {
    setReLoadingState(true)
    const res = await dispatch(getAllTeamUsers(teamId))
    if (res) {
      setReLoadingState(false)
    }
  }

  const handleTableHeight = () => {
    if (tableClicked === 2) {
      setTableClicked(0)
      setTableSize('default')
    }
    if (tableClicked === 0) {
      setTableClicked(tableClicked + 1)
      setTableSize('small')
    }
    if (tableClicked === 1) {
      setTableClicked(tableClicked + 1)
      setTableSize('middle')
    }
  }

  const handleSearchChange = (e) => {
    setSearchedText(e.target.value)
  }

  return (
    <CommonLayout 
      className="content-mr" 
      breadCrumbText="Teams / Team Details"
      backButton={true}
      backUrl={"/teams"}
    >
      <div className="teamNameLabel">
        {currentTeam?.name}
      </div>
      <div className="addUserCard">
        <div className="user-right-content">
          <Search placeholder="Search By User" enterButton allowClear
            onSearch={onSearch} className='userErrorBtn' onChange={handleSearchChange}
          />
        </div>

        <div className="user-left-content">
          <Button type="primary" icon={<UserAddOutlined />} className='addUserBtn' onClick={handleAdd}>
            Add New User
          </Button>
        </div>
      </div>
      {
        (loadingState || reloadingState) ?
          <div className="errorTipDiv">
            <Spin size='middle' tip='Loading...' />
          </div>
          : <>


            <Fullscreen isEnter={isEnter} onChange={setIsEnter}>
              <div className="user-table-card">
                <div className="user-table-topbar">

                  <div className="sharedFilterGrpWrapper">
                    <div className="settingGrp">
                      <ReloadOutlined onClick={handleReload} className='reloadIconStyle' />
                      <ColumnHeightOutlined className='reloadIconStyle' onClick={handleTableHeight} />
                      <FullscreenOutlined onClick={() => { setIsEnter(true) }} className='reloadIconStyle' />
                      <Button type="primary" className='addUserBtn' onClick={() => handleUnassignUsers('')} disabled={!showUnassignButton}>
                        Unassign Users
                      </Button>
                    </div>
                  </div>
                </div>

                <Outlet context={tableSize} />


                {/* modal starts */}

                <Modal
                  open={modalOpen}
                  title='Add a user'
                  onOk={handleSubmit}
                  onCancel={handleCancel}
                  okText={okBtnText}
                  width='40%'
                  destroyOnClose
                  footer={
                    (currentAction === "Add User" || currentAction === "Update User") ? [
                      <Button onClick={handleCancel}>Cancel</Button>,
                      <Button type='primary' onClick={handleSubmit} className='footerBtnNavy' disabled={loading}>{loading ? `Loading...` : okBtnText}
                      </Button>
                    ] : null

                  }
                >
                  {(currentAction === "Add User" || currentAction === "Update User" || currentAction === 'View Details') ?
                    renderTag(currentRecord) : <></>}
                </Modal>
                {
                  showAddAlert &&
                  <Alert message={addAlertText} type={addAlertType}
                    showIcon closable onClose={handleAddAlertClose}
                    className='alertStyle'
                  />
                }
              </div>
            </Fullscreen>
          </>
      }
    </CommonLayout>
  )
}

export default SharedTeamDetailLayout;