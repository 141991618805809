import logger from "common/logger";
import { HttpService } from "services/network/http.service";
const serializeParams = (params: any) => {
  const queryParams = Object.keys(params).reduce((acc, key) => {
    const value = params[key];
    if (value !== undefined) {
      if (typeof value === 'object' && value !== null) {
        // Handle nested objects like filters
        Object.keys(value).forEach(nestedKey => {
          acc.push(`${encodeURIComponent(`${key}[${nestedKey}]`)}=${encodeURIComponent(value[nestedKey])}`);
        });
      } else {
        acc.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      }
    }
    return acc;
  }, [] as string[]);

  return queryParams.join('&');
};
export default class AutomationService extends HttpService {
  async getPromoType(subType: string) {
    try {
      const response = await this.get(`/api/promoType?filters[subtype]=${subType}`);
      return response;
    } catch (error) {
      logger.error(error);
    }
  }
  async getPromoQuestions(id: any) {
    try {
      const response = await this.get(
        `/api/csvTemplate/promotype/questions/${id}`
      );
      return response;
    } catch (error) {
      logger.error(error);
    }
  }

  async createCSVTemplate(payload: any) {
    try {
      const response = await this.post(`/api/csvTemplate`, payload);
      return response;
    } catch (error) {
      logger.error(error);
    }
  }
  async updateCSVTemplate(id: any, payload: any) {
    try {
      const response = await this.put(`/api/csvTemplate/${id}`, payload);
      return response;
    } catch (error) {
      logger.error(error);
    }
  }
  async getCSVTemplates(params: any) {
    try {
      const queryString = serializeParams(params);
      const response = await this.get(`/api/csvTemplate?${queryString}`);
      return response;
    } catch (error) {
      logger.error(error);
    }
  }

  async getTemplateById(id: any) {
    try {
      const response = await this.get(`/api/csvTemplate/${id}`);
      return response;
    } catch (error) {
      logger.error(error);
    }
  }
  async deleteCSVTemplate(id: any) {
    try {
      const response = await this.delete(`/api/csvTemplate/${id}`);
      return response;
    } catch (error) {
      logger.error(error);
    }
  }

  async createXMLTemplate(payload: any) {
    try {
      const response = await this.post(`/api/xmlTemplate`, payload);
      return response;
    } catch (error) {
      logger.error(error);
    }
  }
  async updateXMLTemplate(id: any, payload: any) {
    try {
      const response = await this.put(`/api/xmlTemplate/${id}`, payload);
      return response;
    } catch (error) {
      logger.error(error);
    }
  }
  async getXMLTemplates(params: any) {
    try {
      const queryString = serializeParams(params);
      const response = await this.get(`/api/xmlTemplate?${queryString}`);
      return response;
    } catch (error) {
      logger.error(error);
    }
  }

  async getXMLTemplateById(id: any) {
    try {
      const response = await this.get(`/api/xmlTemplate/${id}`);
      return response;
    } catch (error) {
      logger.error(error);
    }
  }
  async deleteXMLTemplate(id: any) {
    try {
      const response = await this.delete(`/api/xmlTemplate/${id}`);
      return response;
    } catch (error) {
      logger.error(error);
    }
  }
}