import "./TicketReportDetailPage.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Modal, Spin, Tag, Button } from "antd";
import { ExclamationCircleOutlined, WarningOutlined } from "@ant-design/icons";
import moment from "moment";
import CommonLayout from "components/layout/Layout";
import session from "utils/session";
import { TextMessage, SECONDS } from "utils/constants";
import { useParams } from "react-router-dom";
import {
  getSingleErrorTicket,
  updateTicketStatus,
  createCommentAdmin,
  changeApprovalStatus
} from "actions/ticketReport";
import { setPage } from "actions/app";
import { useNavigate } from "react-router-dom";

const TicketReportDetailPage = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { id } = useParams();
  const singleErrorTicket = useSelector(
    (state) => state.ticketReport.singleErrorTicket
  );
  const [loadingState, setLoadingState] = useState(false);

  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);

  const { isAdmin, teamId } = session;
  const [ticketMessage, setTicketMessage] = useState("");
  const [msg, setMsg] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const commentData = useSelector(
    (state) => state.ticketReport.singleErrorTicket.comments
  );

  useEffect(() => {
    dispatch(setPage("Change Request"));
  }, [dispatch]);

  useEffect(() => {
    if (showDeleteAlert) {
      setTimeout(() => {
        setShowDeleteAlert(false);
      }, SECONDS);
    }
  }, [showDeleteAlert]);

  useEffect(() => {
    const getCall = async () => {
      setLoadingState(true);
      const res = await dispatch(getSingleErrorTicket(id));
      // await dispatch(getCommentAdmin(id));
      if (res) {      
        setLoadingState(false);
      }
    };
    getCall();
  }, [dispatch, id]);

  const handleTicketStatus = (val) => {
    Modal.confirm({
      title: "Are you sure you want to decline this Error Ticket?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: () => handleTicketStatusYes(val),
      okType: "primary",
    });
  };

  const handleTicketStatusYes = async (val) => {
    if (msg !== "") {
      setError(false);
      setLoading(true);
      const response = await dispatch(updateTicketStatus(id, "Rejected", msg));
      if (response.error === undefined) {
        window.scrollTo(0, 0);
        setShowDeleteAlert(true);
        setTicketMessage(TextMessage.ERROR_TICKET_RESOLVE_TEXT);
        await dispatch(getSingleErrorTicket(id));
        navigate(`/ticket-report`);
      } else {
        dispatch(getSingleErrorTicket(id));
        window.scrollTo(0, 0);
        setShowDeleteAlert(true);
        setTicketMessage(TextMessage.ERROR_TEXT);
      }
      setMsg("");
      setLoading(false);
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (showConfirmAlert) {
      setTimeout(() => {
        setShowConfirmAlert(false);
        setAlertMessage("");
        setLoading(false);
      }, SECONDS);
    }
  }, [showConfirmAlert]);
  const handleDeleteAlertClose = () => {
    setShowDeleteAlert(false);
    setTicketMessage("");
  };

  const handleAlertClose = () => {
    setShowConfirmAlert(false);
    setAlertMessage("");
  };

  const sendMsg = async (val) => {
    if (msg !== "") {
      setError(false);
      setLoading(true);
      await dispatch(createCommentAdmin(id, msg));
      // await dispatch(getCommentAdmin(id));
      await dispatch(getSingleErrorTicket(id));
      setMsg("");
      setLoading(false);
    } else {
      setError(true);
    }
  };
  const sendConfirmMsg = async (val) => {
    setError(false);
    setLoading(true);
    const res = await dispatch(changeApprovalStatus(id, msg));
    if (res && res.type === "CREATE_COMMENT_ADMINS_FAIL") {
      setShowConfirmAlert(true);
      setAlertMessage(res.error.response.data.message);
    } else {
      await dispatch(getSingleErrorTicket(id));
      setMsg("");
      setLoading(false);
      navigate(`/ticket-report`);
    }
    // await dispatch(createConfirmAdmin(id, msg));
    // await dispatch(getCommentAdmin(id));
  };

  // const handleReOpenTicket = (val) => {
  //   Modal.confirm({
  //     title: "Are you sure you want to Re-Open this Error Ticket?",
  //     icon: <ExclamationCircleOutlined />,
  //     okText: "Yes",
  //     cancelText: "No",
  //     onOk: () => handleReOpenTicketYes(val),
  //     okType: "primary",
  //   });
  // };

  // const handleReOpenTicketYes = async (val) => {
  //   const response = await dispatch(updateTicketStatus(id, "Open"));
  //   if (response.error === undefined) {
  //     dispatch(getSingleErrorTicket(id));
  //     window.scrollTo(0, 0);
  //     setShowDeleteAlert(true);
  //     setTicketMessage(TextMessage.ERROR_TICKET_REOPEN_TEXT);
  //   } else {
  //     dispatch(getSingleErrorTicket(id));
  //     window.scrollTo(0, 0);
  //     setShowDeleteAlert(true);
  //     setTicketMessage(TextMessage.ERROR_TEXT);
  //   }
  // };

  const hasOperationAccess =
    isAdmin || singleErrorTicket?.teamDetails?._id === teamId;
  const isOpen = singleErrorTicket?.status?.toLowerCase() === "open";
  const isValidated = singleErrorTicket?.isValidated;

  return (
    <CommonLayout
      className="content-mr"
      breadCrumbText="Change Request"
      backButton={true}
      id={singleErrorTicket?.ticketNumber}
      backUrl={"/ticket-report"}
    >
      {!loadingState ? (
        <div className="singleError-card-body">
          <div className="ticketFrame">
            <div className="left">
              <div className="leftCard">
                <div className="leftCardHead">
                  <div className="headTitleWrapper">
                    <p className="brief">Ticket Brief</p>
                  </div>
                </div>

                <div className="leftCardBody">
                  <div className="leftCardBodyFrame">
                    <div className="titleDiv">
                      <span className="titleText">Title</span>
                      <span className="titleTextContent">
                        {singleErrorTicket?.title}
                      </span>
                    </div>

                    <div className="typeDiv">
                      <span className="type">Type</span>
                      <span className="typeContent">
                        {singleErrorTicket?.errorTypeDetails?.name}
                      </span>
                    </div>

                    <div className="descDiv">
                      <span className="description">Description</span>
                      <span className="descriptionContent">
                        {singleErrorTicket?.description}
                      </span>
                    </div>
                    <div className="skuDiv">
                      <span className="skuDesc">SKU Keys</span>
                      <span className="skuContent">
                        {(singleErrorTicket.skuId &&
                          singleErrorTicket.skuId.map(function (item, index) {
                            return (
                              <span key={`${index}`}>
                                {index ? `, ${item}` : ` ${item}`}
                              </span>
                            );
                          })) ||
                          ""}
                      </span>
                    </div>
                    <div className="descDiv">
                      <span className="description">Attachment</span>
                      <span className="descriptionContent">
                        <a target="_blank" href={singleErrorTicket?.media?.url} rel='noreferrer'>{singleErrorTicket?.media?.originalFilename}</a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="right">
              <div className="rightCard">
                <div className="rightCardHead">
                  <div className="rightHeadTitleWrapper">
                    <div className="detailWrapper">
                      <span className="detailText">Ticket Details</span>
                    </div>
                    <div className="idWrapper">
                      <span className="idText">
                        #{singleErrorTicket.ticketNumber || ""}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="rightCardBody">
                  <div className="rightCardBodyFrame">
                    <div className="block1">
                      <span className="statusText">Status</span>
                      {singleErrorTicket &&
                        singleErrorTicket.status?.toLowerCase() === "open" && (
                          <Tag color={"red"}>Open</Tag>
                        )}
                      {singleErrorTicket &&
                        singleErrorTicket.status?.toLowerCase() ===
                        "resolved" && <Tag color={"green"}>Resolved</Tag>}
                      {singleErrorTicket &&
                        singleErrorTicket.status?.toLowerCase() ===
                        "rejected" && <Tag color={"red"}>Rejected</Tag>}
                      {singleErrorTicket &&
                        singleErrorTicket.status?.toLowerCase() ===
                        "cancelled" && <Tag color={"red"}>Cancelled</Tag>}
                    </div>

                    <div className="block2">
                      <span className="submittedText">Submitted at</span>
                      <span className="dateText">
                        {singleErrorTicket
                          ? moment(singleErrorTicket?.date).format("MM/DD/YYYY")
                          : ""}
                      </span>
                    </div>

                    <div className="block3">
                      <span className="priorityText">Priority</span>
                      {singleErrorTicket?.priority?.toLowerCase() ===
                        "high" && (
                          <span className="priorityClr highClr">
                            {singleErrorTicket?.priority}
                          </span>
                        )}
                      {singleErrorTicket?.priority?.toLowerCase() === "low" && (
                        <span className="priorityClr lowClr">
                          {singleErrorTicket?.priority}
                        </span>
                      )}
                      {singleErrorTicket?.priority?.toLowerCase() ===
                        "medium" && (
                          <span className="priorityClr mediumClr">
                            {singleErrorTicket?.priority}
                          </span>
                        )}
                      {singleErrorTicket?.priority?.toLowerCase() ===
                        "urgent" && (
                          <span className="priorityClr highClr">
                            {singleErrorTicket?.priority}
                          </span>
                        )}
                    </div>

                    <div className="block4">
                      <span className="raisedByText">Raised By</span>
                      <span className="raisedByName">
                        {singleErrorTicket?.raisedBy}
                      </span>
                    </div>

                    <div className="block4">
                      <span className="pNameText">Promotion Name</span>
                      <span className="pNameSubText">
                        {singleErrorTicket?.promoName || ""}
                      </span>
                    </div>

                    <div className="block4">
                      <span className="pNameText">Action Team</span>
                      <span className="pNameSubText">
                        {singleErrorTicket?.teamDetails?.name || ""}
                      </span>
                    </div>
                  </div>

                  <div className="block4">
                    <span className="pNameText">Event</span>
                    <span className="pNameSubText">
                      {`${singleErrorTicket?.event?.name} (${singleErrorTicket?.event?.eventId})` ||
                        ""}
                    </span>
                  </div>

                  <div className="block4">
                    <span className="pNameText">Adset</span>
                    <span className="pNameSubText">
                      {singleErrorTicket?.adset?.name || ""}
                    </span>
                  </div>
                  <div className="block4">
                    <span className="pNameText">COMP ID</span>
                    <span className="pNameSubText">
                      {singleErrorTicket?.comp_id || ""}
                    </span>
                  </div>
                  <div className="block4">
                    <span className="pNameText">RPM COMP ID</span>
                    <span className="pNameSubText">
                      {singleErrorTicket?.promo?.rpm_comp_id || ""}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {commentData && commentData?.length > 0 ? (
            <div className="mainbodycomment">
              {commentData?.map((comment) => {
                return (
                  <>
                    <div
                      className={
                        comment.raisedBy === session.fullName
                          ? "righta"
                          : "leftc"
                      }
                    >
                      <span
                        className={
                          comment.raisedBy === session.fullName ? "" : ""
                        }
                      ></span>
                      <div
                        className={
                          comment.raisedBy === session.fullName
                            ? "reverseName"
                            : ""
                        }
                      >
                        <span
                          className={
                            comment.raisedBy === session.fullName
                              ? "adminName"
                              : "sysName"
                          }
                        >
                          {comment.createdBy ===
                            `${singleErrorTicket?.createdBy}`
                            ? comment.raisedBy
                            : "Petco Support Team"}
                        </span>
                        <span
                          className={
                            comment.raisedBy === session.fullName
                              ? "sysTime"
                              : "adminTime"
                          }
                        >
                          {moment(`${comment.createdAt}`).fromNow()}
                        </span>
                      </div>
                      <span
                        className={
                          comment.raisedBy === session.fullName
                            ? "adminComment"
                            : "sysComment"
                        }
                      >
                        {comment.comment}
                      </span>
                    </div>
                  </>
                );
              })}
            </div>
          ) : (
            ""
          )}
          {isOpen && hasOperationAccess && (
            <textarea
              name=""
              id=""
              placeholder="Add comment to this ticket"
              value={msg}
              onChange={(e) => {
                setMsg(e.target.value);
                setError(false);
              }}
              style={{ resize: "none" }}
              className="newtexta"
            />
          )}

          <span className="errormsg">
            {error ? "Please add a comment" : ""}
          </span>
          <div className="buttonFrame">
            {isOpen && isValidated && hasOperationAccess && (
              <Button className="sendmsg" disabled={loading} onClick={sendMsg}>
                {" "}
                {loading ? `Loading...` : "Add Comment"}
              </Button>
            )}
            {isOpen && !isValidated && hasOperationAccess && (
              <Button
                className="sendmsg"
                disabled={loading}
                onClick={sendConfirmMsg}
              >
                {" "}
                {loading ? `Loading...` : "Confirm"}
              </Button>
            )}

            {isOpen && !isValidated && hasOperationAccess && (
              <button className="closeBtn" onClick={handleTicketStatus}>
                Decline
              </button>
            )}
          </div>

          {showDeleteAlert && (
            <Alert
              message={ticketMessage}
              type="error"
              showIcon
              closable
              onClose={handleDeleteAlertClose}
              className="alertStyle"
            />
          )}
        </div>
      ) : (
        <div className="errorTipDiv">
          <Spin size="middle" tip="Loading..." />
        </div>
      )}

      {showConfirmAlert && (
        <Alert
          message={alertMessage}
          type="error"
          showIcon
          closable
          onClose={handleAlertClose}
          icon={<WarningOutlined />}
          className="alertStyle"
        />
      )}
    </CommonLayout>
  );
};

export default TicketReportDetailPage;
