/* eslint-disable react-hooks/exhaustive-deps */
import { Input, Select, DatePicker } from "antd";
import {
  useState,
  useEffect,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getAllAdsets, getAllEvents } from "actions/calendar";
import { getAllUsers } from "actions/users";
import {
  getPromoTypeStage,
  getPromoTypeForPromo,
} from "actions/promotions/index";
import { DATE_FORMAT_DASH } from "utils/constants";
const { RangePicker } = DatePicker;

const { Option } = Select;

const requiredFields = ["name", "adset", "event", "promoTypeId", "owner", "promoId"];

const CreateStaticForm = forwardRef((props, ref) => {
  const { setStages, setStaticQuestions, errormsg, type } = props;
  const { adsets, events } = useSelector((state) => state.calendar);

  const { promoTypeForPromo } = useSelector((state) => state.promo);
  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState({});
  const dispatch = useDispatch();
  const [userOptionData, setUserOptionData] = useState([]);

  // const adSetsDD = useMemo(() => {
  //   return adsets.filter((a) => moment(a.startDate).isAfter(moment().subtract(1, 'day')));
  // }, [adsets]);
  /// PT2-381
  const adSetsDD = useMemo(() => {
    return adsets.filter((a) => moment(a.endDate).isAfter(moment()));
  }, [adsets]);

  
  useEffect(() => {
    const data = props.data;

    if (!events.length && data?.event) {
      return;
    }
    if (data) {
      const _event = events.find(
        (e) => e?._id === data?.event || e?._id === data?.event?._id || e?._id === data?._id
      );

      setFormData({
        name: data.name,
        adset: data.adset?._id,
        promoTypeId: data.promoTypeId,
        owner: data.owner?._id,
        startDate: data.startDate,
        endDate: data.endDate,
        event: _event,
        comp_id: data.comp_id,
        promoId: data.promoId,
        eventId: data.event?.eventId,
        promoIdName: data.promoIdName
      });
    }
  }, [props.data, events]);

  useEffect(() => {
    if (
      (formData?.event?._id || formData?.event) &&
      promoTypeForPromo?.length === 0
    ) {
      errormsg?.("No Promo type found");
    }
  }, [promoTypeForPromo, formData.event, errormsg]);

  useEffect(() => {
    const getPromoTypeData = async () => {
      const res = await dispatch(
        getPromoTypeStage(
          formData.promoTypeId,
          formData.event._id || formData.event
        )
      );
      const data = res?.payload?.data?.data?.data || [];
      if (res?.error?.response?.data?.message) {
        errormsg?.(res?.error?.response?.data?.message);
      }
      setStages(data);
      const staticData = res?.payload?.data?.data?.staticQuestion || [];
      setStaticQuestions(staticData);
    };
    if (formData.promoTypeId && formData.event && type === "create") {
      getPromoTypeData();
    }
  }, [
    formData.promoTypeId,
    formData.event,
    dispatch,
    setStages,
    setStaticQuestions,
    errormsg,
    type,
  ]);

  useEffect(() => {
    const getData = async () => {
      const res = await dispatch(getAllUsers());
      if (res.error === undefined) {
        setUserOptionData(res.payload.data.data.users || []);
      }
    };
    getData();
    dispatch(getAllAdsets());
  }, [dispatch]);

  useEffect(() => {
    const getData = async () => {
      const res = await dispatch(getPromoTypeForPromo(formData.event._id));
      if (res?.error?.response?.data?.message) {
        errormsg?.(res?.error?.response?.data?.message);
      }
    };
    if (formData?.event?._id) {
      getData();
    }
  }, [formData.event, dispatch, errormsg]);

  useEffect(() => {
    dispatch(getAllEvents({ perPage: 1000, filters: { adset: [formData.adset] } }));
  }, [formData.adset, dispatch]);

  const MAX_NAME_LENGTH = 40;
  const handleFormDataChange = (key, value) => {
    setFormData((formData) => {
      if (key === "adset") {
        delete formData.event;
        delete formData.promoTypeId;
        delete formData.date;
        setStages([]);
        setStaticQuestions([]);
      } else if (key === "event") {
        delete formData.promoTypeId;
        delete formData.date;
        delete formData.promoId;
        delete formData.promoIdName;
        setStages([]);
        setStaticQuestions([]);
      }
      return { ...formData, [key]: value };
    });
    if (!value) {
      setFormError({
        ...formError,
        [key]: !value ? "This field is required" : "",
      });
    } else {
      if (key === "name" && value && value?.length > MAX_NAME_LENGTH) {
        setFormError({
          ...formError,
          [key]:
            value.length > MAX_NAME_LENGTH
              ? `This field has ${MAX_NAME_LENGTH} character limit`
              : "",
        });
      } else {
        const _formError = { ...formError };
        delete _formError[key];
        setFormError(_formError);
      }

    }
  };

  useImperativeHandle(ref, () => ({
    isValid() {
      let valid = true;
      const errors = { ...formError };
      requiredFields.forEach((item) => {
        if (!formData[item]) {
          errors[item] = "This field is required";
        }
      });
    
      valid = Object.values(errors).length === 0;
      if (Object.keys(errors).length) {
        setFormError(errors);
      }
      return valid;
    },
    getFormData() {
      const payload = { ...formData };
      payload.startDate = formData.event.startDate;
      payload.endDate = formData.event.endDate;
      payload.event = formData.event._id;
      delete payload.date;
      return payload;
    },
    getPromoTypeName() {
      if (!promoTypeForPromo || promoTypeForPromo.length === 0) {
        return "Promo type data is not available";
      }
      if (!formData.promoTypeId) {
        return "Promo type ID is missing";
      }
      const matchingPromoType = promoTypeForPromo.find(
        (promo) => promo._id === formData.promoTypeId
      );
      return matchingPromoType ? matchingPromoType.promoType : "Promo type not found";
    }
  }));
  if (props.data && !Object.keys(formData).length) {
    return;
  }

  const handleEventChange = (value) => {
    const event = events.find((e) => e._id === value);
    handleFormDataChange("event", event);
    handleFormDataChange("eventId", event?.eventId || "");
  };

  const promoHandleChange = (value) => {
    const promo = formData.event?.promoIds?.find((e) => e.promo_id === value);
    // handleFormDataChange("promoId", value);
    handleFormDataChange("promoIdName", promo?.promo_id_name);
  }

  return (
    <>
      <div className="flex space-between gap-16 create-promo-new">

        <div className="input-frame  width-50">
          <div className="d-label">
            <span className="spanReq">*</span>{" "}
            <label className="input-label">
              Adset Name
            </label>
          </div>

          <Select
            className="w-full"
            onChange={(e) => {
              handleFormDataChange("adset", e);
            }}
            placeholder="Search and select"
            value={formData.adset}
            disabled={props.disabled} showSearch
            filterOption={(input, option) => {
              return (option?.children?.toLowerCase() ?? "").includes(
                input?.toLowerCase()
              );
            }}
          >
            {adSetsDD?.map((item) => (
              <Option key={item._id}>{item.name}</Option>
            ))}
          </Select>
          <span className="error-label">{formError.adset}</span>
        </div>

        <div className="input-frame width-50">
          <div className="input-frame">
            <div className="d-label">
              <span className="spanReq">*</span>{" "}
              <label className="input-label">Event Name?</label>
            </div>
            <Select
              className="w-full"
              onChange={handleEventChange}
              key={formData?.adset}
              placeholder="Select"
              disabled={!formData.adset || props.disabled}
              defaultValue={formData.event?._id} showSearch
              filterOption={(input, option) => {
                return (option?.children?.toLowerCase() ?? "").includes(
                  input?.toLowerCase()
                );
              }}
            >
              {events?.map((item) => (
                <Option key={item._id}>{item.name}</Option>
              ))}
            </Select>
            <span className="error-label">{formError.event}</span>
          </div>
        </div>
      </div>
      <div className="flex space-between gap-16 create-promo-new top-padding">

        <div className="input-frame width-50">
          <div className="d-label">
            <span className="spanReq">*</span>{" "}
            <label className="input-label">
              Event ID
            </label>
          </div>
          <Input
            className="input-text-field"
            onChange={(val)=> handleEventChange(val)

            }
            placeholder="Event ID"
            value={formData.eventId}
            disabled={true}
          />
          <span className="error-label">{formError.eventId}</span>
        </div>
        <div className="input-frame width-50">
          <div className="d-label">
            <span className="spanReq">*</span>{" "}
            <label className="input-label">
              Promo ID?
            </label>
          </div>
          <Select
            className="w-full"
            onChange={(value)=> {
              promoHandleChange(value);
              handleFormDataChange("promoId",value)

            }}
            key={formData.promoId}
            placeholder="Select"
            disabled={!formData.adset || props.disabled}
            defaultValue={formData.promoId} showSearch
            filterOption={(input, option) => {
              return (option?.children?.toLowerCase() ?? "").includes(
                input?.toLowerCase()
              );
            }}
          >
            {formData.event?.promoIds?.map((item) => (
              <Option key={item?.promo_id}>{item?.promo_id}</Option>
            ))}
          </Select>
          <span className="error-label">{formError.promoId}</span>
        </div>
      </div>

      <div className="flex space-between gap-16 create-promo-new top-padding">

        <div className="input-frame width-50">
          <div className="d-label">
            <label className="input-label">
              Promo ID Name
            </label>
          </div>
          <Input
            className="input-text-field"
            onChange={(e) => handleFormDataChange("name", e.target.value)}
            value={formData.promoIdName}
            disabled
          />
          <span className="error-label">{formError.name}</span>
        </div>
        <div className="input-frame width-50">
          <div className="d-label">
            <span className="spanReq">*</span>{" "}
            <label className="input-label">
              Offer Name?
            </label>
          </div>
          <Input
            className="input-text-field"
            onChange={(e) => handleFormDataChange("name", e.target.value)}
            placeholder="Enter here"
            value={formData.name}
            disabled={props.type === "edit" || props.type === "view" || props.disabled}
          />
          <span className="error-label">{formError.name}</span>
        </div>
      </div>

      {formData.comp_id && <div className="input-frame">
        <div className="d-label">
          <label className="input-label">
            COMP ID
          </label>
        </div>
        <Input
          className="input-text-field"
          placeholder="COMP ID"
          value={formData.comp_id}
          disabled
        />
      </div>
      }
   {props?.data?._id &&    <div className="input-frame">
        <div className="d-label">
          <label className="input-label">
            RPM COMP ID
          </label>
        </div>
        <Input
          className="input-text-field"
          placeholder=""
          value={props?.data?.rpm_comp_id}
          disabled
        />
      </div>}
      <div className="top-padding">
        <div className="date-frame">
          <div className="d-label">
            <label className="input-label">
              <span className="spanReq">*</span> When does the promo start and end?
            </label>
          </div>
          <RangePicker
            format={DATE_FORMAT_DASH}
            onChange={(value, dateString) => {
              handleFormDataChange("date", dateString);
            }}
            className="select-date w-full"
            allowClear={false}
            value={
              formData?.event?.startDate && formData?.event?.endDate
                ? [
                  moment.utc(formData?.event?.startDate),
                  moment.utc(formData?.event?.endDate),
                ]
                : undefined
            }
            disabled={true}
            placeholder={['Start Date (MM/DD/YYYY)', 'End Date (MM/DD/YYYY)']}
            key={formData?.event?._id}
          />
          <span className="error-label">{formError.date}</span>
        </div>
      </div>

      <div className="flex space-between gap-16">
        <div className="width-50">
          <div className="d-label">
            <span className="spanReq">*</span>{" "}
            <label className="input-label">
              Offer Type
            </label>
          </div>
          <Select
            className="w-full"
            showSearch
            onChange={(value) => {
              handleFormDataChange("promoTypeId", value);
            }}
            placeholder="Select"
            value={formData.promoTypeId}
            disabled={!formData.event || props.disabled}
            key={formData?.event?._id}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {(type === "create"
              ? promoTypeForPromo
              : [
                {
                  promoType: props.data?.promotype?.name,
                  _id: props.data?.promotype?.promoTypeId,
                },
              ]
            )?.map((item) => (
              <Option key={item._id}>{item.promoType}</Option>
            ))}
          </Select>
          <span className="error-label">{formError.promoTypeId}</span>
        </div>

        <div className="width-50">
          <div className="d-label">
            <span className="spanReq">*</span>{" "}
            <label className="input-label">
              Offer Owner Name
            </label>
          </div>
          <Select
            className="w-full"
            showSearch
            filterOption={(input, option) => {
              return (option?.children?.toLowerCase() ?? "").includes(
                input?.toLowerCase()
              );
            }}
            onChange={(value) => {
              handleFormDataChange("owner", value);
            }}
            placeholder="Select"
            value={formData.owner}
            disabled={props.type === "edit" || props.type === "view" || props.disabled}
          >
            {(
              userOptionData?.filter((item) => item.isActive === true) || []
            )?.map((item) => (
              <Option key={item._id}>{item.fullName || item.firstName}</Option>
            ))}
          </Select>
          <span className="error-label">{formError.owner}</span>
        </div>
      </div>
    </>
  );
});
export default CreateStaticForm;
