/* eslint-disable no-unused-vars */
import logo from "image/Frame 147.svg";
import { useState, useEffect } from "react";
import { Button, Checkbox, Input } from "antd";
import {
  MailOutlined,
  LockOutlined, 
} from "@ant-design/icons";
import {
  FormControl,
  Container,
  WrapperImg,
} from "components/common/LoginStyle";
import Footer from "components/common/footer";
import Error from "components/common/error";
import { Link, useNavigate } from "react-router-dom";
import { FIELD_REQUIRED } from "utils/constants";
import session from "utils/session";
import { Validator } from "utils/validations";
import { useDispatch } from "react-redux";
import { login, sendFCMToken } from "actions/login";
import { setPage } from "actions/app";
import { requestForToken } from "utils/firebase-notification";
import packageInfo from "./../../../package.json";

const Login = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState("");
  const [isChecked, setIsChecked] = useState("");
  const [userError, setUserError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  useEffect(() => {
    if (session.checkbox && session.user !== "") {
      setIsChecked(session.checkbox);
      setUser(session.user);
      setPassword(session.password);
    }
    dispatch(setPage("Login"));
  }, [dispatch]);

  const handleCheckbox = (e) => {
    e.preventDefault();
    setIsChecked(e.target.checked);
  };

  const handleLogin = async (e) => {
    if (e) {
      e.preventDefault();
  }
    if (
      userError !== "" ||
      passwordError !== "" ||
      user === "" ||
      password === ""
    ) {
      if (user === "") {
        setUserError(FIELD_REQUIRED);
      } else {
        setUserError(userError);
      }
      if (password === "") {
        setPasswordError(FIELD_REQUIRED);
      } else {
        setPasswordError(passwordError);
      }
      return;
    }

    setLoading(true);

    const res = await dispatch(
      login({
        email: user,
        password: password,
      })
    );

    // let token = '';
    // token =  await requestForToken()
    // if(token){
    //   await dispatch(sendFCMToken(token))
    // }

    if (res.error === undefined) {
      setLoading(false);
    } else {
      setLoading(false);
    }

    if (res?.payload?.data?.status) {
      const data=res?.payload?.data?.data?.payload?.user;
      session.setPermissions(data?.permissions);
      if (isChecked && user !== "") {
        session.setCheckbox(isChecked);
        session.setUser(user);
        session.setPassword(password);
      }
      navigate("/");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  const handleUserChange = (e) => {
    const { value } = e.target;
    setUser(value);
    setUserError(Validator.validate("email", value, null, null, true));
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setPassword(value);
    setPasswordError(Validator.validate("password", value, 6, 30, true));
  };

  return (
    <>
      <Container>
        <img src={logo} alt="logo" />
        <WrapperImg>
          <div className="head-box">
            <div className="item-count">
              <div className="item-tab">
                <span className="tab1">
                  <div className="text-mode">Login</div>
                </span>
                <span className="ink"></span>
              </div>
            </div>
            <span className="line-only"></span>
          </div>

          <Error />
          <form className="form" onSubmit={handleLogin}>
            <FormControl>
              <Input
                type="text"
                size="large"
                placeholder="Email"
                prefix={<MailOutlined />}
                value={user}
                onChange={handleUserChange}
                onKeyDown={handleKeyDown}
              />
              <span className="error-label">{userError}</span>
            </FormControl>
            <FormControl>
              <Input.Password
                size="large"
                placeholder="Password"
                type="password"
                value={password}
                onChange={handlePasswordChange}
                prefix={<LockOutlined />}
                onKeyDown={handleKeyDown}
              />
              <span className="error-label">{passwordError}</span>
            </FormControl>
            <div className="display-login">
              <Checkbox
                value={isChecked}
                onChange={handleCheckbox}
                className="check-style"
              >
                Remember me
              </Checkbox>
              <Link to="/forgot">
                <h5 className="forgot-text">Forgot your password?</h5>
              </Link>
            </div>
            <Button
              onClick={handleLogin}
              className="addUserBtnAuth"
              block
              disabled={loading}
            >
              {loading ? `Loading...` : "Sign In"}
            </Button>
          </form>
          <Footer />
          <span className="version">Version: {packageInfo.version}</span>
        </WrapperImg>
      </Container>
    </>
  );
};

export default Login;
