/* eslint-disable no-unused-vars */
import './Teams.css'
import { useEffect, useState } from "react";
import {
  ColumnHeightOutlined, DeleteOutlined, SearchOutlined,
  ExclamationCircleOutlined,
  UserAddOutlined, FullscreenOutlined, ReloadOutlined
} from '@ant-design/icons';
import {
  Alert, Button, Col, Input,
  Modal, Row, Spin, Switch, Tag,
  Checkbox
} from 'antd';
import Fullscreen from "fullscreen-react";
import { useDispatch, useSelector } from "react-redux";
import CommonLayout from "components/layout/Layout";
import CustomTable from "components/common/table/TableNew";
import TableSearchDropdown from 'components/TableSearchDropdown';
import { TEAMS_LIST_COLUMNS } from 'utils/column';
import { Validator } from 'utils/validations';
import { TextMessage, FIELD_REQUIRED, SECONDS } from 'utils/constants';
import { setPage } from "actions/app";
import { updatePromoTypeBySearch } from 'actions/promoTypes';
import { useNavigate } from 'react-router';
import { getAllTeams, createTeam, updateTeamByStatus, deleteTeamById, updateTeamById, updateLocalCurrentTeam } from 'actions/teams';


const { Search } = Input;

const Teams = () => {
  const [isEnter, setIsEnter] = useState(false);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { teams, total, isVcm } = useSelector(state => state.teams);
  const [currPage, setCurrPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [tableLoading, setTableLoading] = useState(false);
  const [showTeamDeleteAlert, setShowTeamDeleteAlert] = useState(false)
  const [showTeamDeleteErrorAlert, setShowTeamDeleteErrorAlert] = useState(false)
  const searchedPromoTypes = useSelector((state) => state.promoTypes.searchedPromoTypes)

  const [tableSize, setTableSize] = useState('default')
  const [tableClicked, setTableClicked] = useState(0)
  const [modalOpen, setModalOpen] = useState(false)
  const [currentAction, setCurrentAction] = useState('')
  const [currentRecord, setCurrentRecord] = useState({})
  const [isVCM, setIsVCM] = useState(false)
  const [promoTypeName, setPromoTypeName] = useState('')
  const [status, setStatus] = useState(true)
  const [promoTypeNameError, setPromoTypeNameError] = useState('')
  const [okBtnText, setOkBtnText] = useState('')
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const [showUpdateAlert, setShowUpdateAlert] = useState(false)
  const [showAddAlert, setShowAddAlert] = useState(false)
  const [loading, setLoading] = useState(false)
  const [promoDeActive, setPromoDeActive] = useState(false)
  const [promoActive, setPromoActive] = useState(true)
  const [loadingState, setLoadingState] = useState(false)
  const [searchedText, setSearchedText] = useState('')
  const [reloadingState, setReLoadingState] = useState(false)
  const [errorMessage, setErrorMessage] = useState('');
  const [vcmTeamAvailable, setVCMTeamAvailable] = useState({});

  useEffect(() => {
    dispatch(setPage('Teams'))
  }, [dispatch])

  useEffect(() => {
    if (searchedText === '') {
      dispatch(updatePromoTypeBySearch([]))
    }
  }, [searchedText, dispatch])

  const getData = async (params = {}, isLoading = true) => {
    if (params?.search !== '' && searchedText) {
      params.search = searchedText;
    }
    if (isLoading) {
      setLoadingState(true);
    }
    const res = await dispatch(getAllTeams(params));
    if (res?.payload?.data?.data?.vcmTeam.name) {
      setVCMTeamAvailable(res?.payload?.data?.data?.vcmTeam);
    } else {
      setVCMTeamAvailable({});
    }
    if (res) {
      setLoadingState(false);
      setTableLoading(false);
    }
  };
  useEffect(() => {
    const getCall = async () => {
      setLoadingState(true)
      getData({ page: 1 }, false);
    }
    getCall()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    if (showAddAlert) {
      setTimeout(() => {
        setShowAddAlert(false)
      }, SECONDS);
    }
    if (showUpdateAlert) {
      setTimeout(() => {
        setShowUpdateAlert(false)
      }, SECONDS);
    }
    if (showDeleteAlert) {
      setTimeout(() => {
        setShowDeleteAlert(false)
      }, SECONDS);
    }
    if (showTeamDeleteAlert) {
      setTimeout(() => {
        setShowTeamDeleteAlert(false)
      }, SECONDS);
    }
    if (showTeamDeleteErrorAlert) {
      setTimeout(() => {
        setShowTeamDeleteErrorAlert(false)
      }, SECONDS);
    }
  }, [showAddAlert, showUpdateAlert, showDeleteAlert, showTeamDeleteAlert, showTeamDeleteErrorAlert])

  const changeStatusYes = async (id) => {
    Modal.confirm({
      title: 'Are you sure you want to deactivate this Team?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => promoDeactivateYes(id, false),
      okCancel: promoDeactivateNo,
      okType: 'primary',
    });
  }

  const promoDeactivateYes = async (id, checked) => {
    const resp = await dispatch(updateTeamByStatus(id, checked))
    if (resp.error) {
      setErrorMessage(resp?.error?.response?.data?.message);
      setShowTeamDeleteErrorAlert(true)
    }
  }
  const promoDeactivateNo = () => {
    setPromoActive(true)
  }

  const changeStatusNo = async (id) => {
    Modal.confirm({
      title: 'Do you want to activate this Team?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => promoActivateYes(id, true),
      okCancel: promoActivateNo,
      okType: 'primary',
    });
  }

  const promoActivateYes = async (id, checked) => {
    const resp = await dispatch(updateTeamByStatus(id, checked))
    if (resp.error) {
      setErrorMessage(resp?.error?.response?.data?.message);
      setShowTeamDeleteErrorAlert(true)
    }
  }
  const promoActivateNo = () => {
    setPromoDeActive(false)
  }

  const setupRows = (promoTypes) => {
    return promoTypes?.map((o) => {
      return {
        "teamNameDisplay": o.name,
        "isVCM": o.isVCM,
        "teamName": (
          <div className='teamNameAndTag'>
            <div>{o.name}</div>
            {o.isVCM && <Tag className="status-symbol-incomplete">VCM team</Tag>}
          </div>
        ),
        "_id": o._id,
        'status': o.isActive ?
          <Switch checked={promoActive} className='switchStyle' onChange={() => changeStatusYes(o._id)} /> : <Switch checked={promoDeActive} onChange={() => changeStatusNo(o._id)} />,
        'statusValue': o.status,
        'members': o.members,
        'isStatus': o.status
      }
    })
  }

  const renderTag = () => {

    return (
      <form
        onSubmit={handleSubmit}
      >

        <Row gutter={16} className='mb-25'>
          <Col className="gutter-row" span={24}>
            <div>
              <label>
                <span className='spanReq'>*</span> Team Name
              </label>

              <Input placeholder='Write here' value={promoTypeName} className='mt-5'
                onChange={handlePromoTypeName} maxLength={31}
              />
              <span className='errorLabel'>{promoTypeNameError}</span>
            </div>
          </Col>
        </Row>
        {(!vcmTeamAvailable.name || vcmTeamAvailable.name == promoTypeName) && (<Row gutter={16} className='mb-25'>
          <Col className="gutter-row" span={24}>
            <div>
              <Checkbox
                checked={isVCM}
                onChange={onVcmTeamChange}
              >
                Select the checkbox if this is a VCM Team
              </Checkbox>
            </div>
          </Col>
        </Row>)}

        {
          currentAction === 'Update Team' && <Row gutter={16} className='mb-25'>
            <Col className="gutter-row" span={24}>
              <div>
                <label className='mr-10'>Status</label>
                <Tag color={status ? 'green' : 'red'}>{status ? 'Active' : 'Inactive'}</Tag>
              </div>
            </Col>
          </Row>
        }

      </form>
    );
  };

  const handleAdd = () => {
    setModalOpen(true)
    setCurrentAction('Create Team')
    setOkBtnText('Add')
  }

  const handleCancel = () => {
    setModalOpen(false)
    setCurrentAction('')
    setOkBtnText('')
    setPromoTypeName('')
    setStatus(true)
    setIsVCM(false);
    setPromoTypeNameError('')
  }

  const handlePromoTypeName = (e) => {
    const { value } = e.target
    if (currentAction === 'Create Team') {
      setPromoTypeNameError(teams.findIndex(item => item.name === value) !== -1 ? "Team with this name already exists!" : Validator.validate('alphanumericspace', value, null, null, true))
      setPromoTypeName(value)
    } else {
      setPromoTypeNameError(Validator.validate('alphanumericspace', value, null, null, true))
      setPromoTypeName(value)
    }
  }

  const onVcmTeamChange = (e) => {
    setIsVCM(e.target.checked);
  };

  const handleSubmit = async (e) => {

    e.preventDefault()

    let newRecord = {};

    if (currentAction === 'Create Team') {
      if (promoTypeNameError !== "" || promoTypeName === "") {
        setPromoTypeNameError(promoTypeName === "" ? FIELD_REQUIRED : promoTypeNameError)
        return;
      }

      const existingVcmTeam = teams.some(team => team.isVCM);

      if (existingVcmTeam && isVCM) {
        Modal.warning({
          title: 'A VCM team already exists. You cannot create another one.',
          icon: <ExclamationCircleOutlined />
        });
        return;
      }
      setLoading(true)
      newRecord = {
        name: promoTypeName,
        status: true,
        isVCM: isVCM
      }

      const response = await dispatch(createTeam(newRecord.name, newRecord.status, newRecord.isVCM))

      if (response.error === undefined) {
        handleCancel()
        window.scrollTo(0, 0);
        setLoading(false)
        setShowAddAlert(true)
        getData({ page: 1 }, false);
      } else {
        handleCancel()
        setLoading(false)
        setErrorMessage(response?.error?.response?.data?.message);
        setShowTeamDeleteErrorAlert(true)
        window.scrollTo(0, 0);

      }
    }

    if (currentAction === 'Update Team') {

      if (promoTypeNameError !== "" || promoTypeName === "") {
        setPromoTypeNameError(promoTypeName === "" ? FIELD_REQUIRED : promoTypeNameError)
        return;
      }

      const existingVcmTeam = teams.some(team => team.isVCM && team._id !== currentRecord._id);

      if (existingVcmTeam && isVCM) {
        Modal.warning({
          title: 'A VCM team already exists. You cannot create another one.',
          icon: <ExclamationCircleOutlined />
        });
        return;
      }
      setLoading(true)
      newRecord = {
        name: promoTypeName,
        status: status,
        _id: currentRecord._id,
        isVCM: isVCM,
      }
      const response = await dispatch(updateTeamById(newRecord._id, newRecord.name, newRecord.status, newRecord.isVCM))

      if (response.error === undefined) {
        handleCancel()
        window.scrollTo(0, 0);
        setLoading(false)
        setShowUpdateAlert(true)
        getData({ page: 1 }, false);
      } else {
        handleCancel()
        setLoading(false)
        setErrorMessage(response?.error?.response?.data?.message);
        setShowTeamDeleteErrorAlert(true)
        window.scrollTo(0, 0);
      }

    }

  }

  const updatePromoType = (record) => {
    if (record.status.props.checked) {
      Modal.warning({
        title: 'Cannot Edit Active Team',
        icon: <ExclamationCircleOutlined />
      });
    } else {
      setModalOpen(true)
      setCurrentAction('Update Team')
      setCurrentRecord(record)
      setPromoTypeName(record.teamNameDisplay)
      setStatus(record.isActive)
      setIsVCM(record.isVCM);
      setOkBtnText('Save')
    }
  }

  const deletePromoType = (value) => {
    if (value.members) {
      setShowTeamDeleteAlert(true);
    } else {
      Modal.confirm({
        title: TextMessage.CONFIRMATION_TEXT,
        icon: <ExclamationCircleOutlined />,
        okText: 'Yes',
        cancelText: 'No',
        onOk: () => deletePromoTypeYes(value),
        okType: 'primary',
      });
    }
  }

  const checkPermission = (row) => {
    const isActive = row.status.props.checked;
    const hasAssignedUsers = row.members !== 0;

    return isActive || hasAssignedUsers;
  }

  const deletePromoTypeYes = async (value) => {
    const resp = await dispatch(deleteTeamById(value._id))
    if (resp.error) {
      setErrorMessage(resp?.error?.response?.data?.message);
      setShowTeamDeleteErrorAlert(true)
    } else {
      window.scrollTo(0, 0);
      setShowDeleteAlert(true);
      getData({ page: 1 }, false);
      // handleReload();
    }

  }

  const handleDeleteAlertClose = () => {
    setShowDeleteAlert(false)
    setShowTeamDeleteAlert(false);
    setShowTeamDeleteErrorAlert(false)
  }

  const handleUpdateAlertClose = () => {
    setShowUpdateAlert(false)
    setCurrentAction('')
  }

  const handleAddAlertClose = () => {
    setShowAddAlert(false)
    setCurrentAction('')
  }

  const onSearch = async (value) => {
    if (value) {
      getData({ page: 1 })
    } else {
      setSearchedText('');
      getData({ page: 1, search: '' })
    }
  }

  const handleReload = async () => {
    setReLoadingState(true)
    const res = await dispatch(getAllTeams())
    if (res) {
      setReLoadingState(false)
    }
  }

  const setColumns = () => {
    let cols = [];

    TEAMS_LIST_COLUMNS.forEach((o) => {
      if (o.dataIndex === "teamName" || o.dataIndex === "members") {
        const colsOptions = {
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
            return (
              <TableSearchDropdown setSelectedKeys={setSelectedKeys}
                selectedKeys={selectedKeys}
                confirm={confirm}
                clearFilters={clearFilters}
                dataIndex={o.dataIndex} />
            )
          },
          filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,

        }
        cols.push({ ...o, ...colsOptions });
      }
      else {
        cols.push(o);
      }

    });
    return cols
  }

  const handleTableHeight = () => {
    if (tableClicked === 2) {
      setTableClicked(0)
      setTableSize('default')
    }
    if (tableClicked === 0) {
      setTableClicked(tableClicked + 1)
      setTableSize('small')
    }
    if (tableClicked === 1) {
      setTableClicked(tableClicked + 1)
      setTableSize('middle')
    }
  }

  const handleSearchChange = (e) => {
    setSearchedText(e.target.value)
  }
  const viewTeamDetails = async (record) => {
    let newRecord = {
      "_id": record._id,
      "name": record.teamNameDisplay,
      "status": record.statusValue,
    }
    dispatch(updateLocalCurrentTeam(newRecord));
    navigate(`/teams/team-detail/${record._id}`)
  }

  return (
    <CommonLayout className="content-mr" breadCrumbText='Teams'>
      <div className="promo-type-card">
        <div className="promo-right-content">
          <Search placeholder="Search Team" enterButton allowClear className='searchPromoTypeBtn'
            onSearch={onSearch} onChange={handleSearchChange} />
        </div>

        <div className="promo-left-content">
          <Button type="primary" icon={<UserAddOutlined />} className='addPromoTypeBtn' onClick={handleAdd}>
            Add New Team
          </Button>
        </div>
      </div>
      {
        (loadingState || reloadingState) ?
          <div className="errorTipDiv">
            <Spin size='middle' tip='Loading...' />
          </div>
          : <>

            <Fullscreen isEnter={isEnter} onChange={setIsEnter}>
              <div className="promo-table-card">
                <div className="promo-table-topbar">
                  <div className="promoRightFilterGrpWrapper">
                    <div className="promoRightSettingGrp">
                      <ReloadOutlined onClick={handleReload} className='reloadIconStyle' />
                      <ColumnHeightOutlined className='reloadIconStyle' onClick={handleTableHeight} />
                      <FullscreenOutlined onClick={() => { setIsEnter(true) }} className='reloadIconStyle' />
                    </div>
                  </div>
                </div>

                {/* table */}

                <div className='w-100'>
                  <CustomTable
                    columns={setColumns()}
                    rows={setupRows(searchedPromoTypes.length > 0 ? teams : teams)}
                    isEditable={true}
                    isDeleteable={true}
                    rowKey="_id"
                    onEdit={updatePromoType}
                    onDelete={deletePromoType}
                    total={total}
                    size={tableSize}
                    isViewable={true}
                    isPopup={true}
                    isPromoView={false}
                    onView={viewTeamDetails}
                    isLoading={tableLoading}
                    checkRowDeleatable={checkPermission}
                    onChange={async (
                      { current, pageSize },
                      filter = {},
                      { field, order, columnKey }
                    ) => {
                      const params = {
                        page: current,
                        perPage: pageSize,
                        sortBy: order && columnKey,
                        orderBy: order,
                      };
                      setCurrPage(current);
                      setPageSize(pageSize);
                      const keys = Object.keys(filter);
                          keys.forEach((key) => {
                            if (filter[key]) {
                              if (!params.filters) {
                                params.filters = {};
                              }
                              if (
                                key === "teamName" ||
                                key === "members"
                              ) {
                                params.filters["name"] = filter[key][0];
                              } 
                              
                            }
                          });
                      setTableLoading(true);
                      getData(params, false);
                    }}
                  />
                </div>

                <Modal
                  open={modalOpen}
                  title={currentAction}
                  onOk={handleSubmit}
                  onCancel={handleCancel}
                  okText={okBtnText}
                  destroyOnClose
                  footer={
                    (currentAction === "Create Team" || currentAction === "Update Team") ? [
                      <Button onClick={handleCancel}
                      >Cancel</Button>,
                      <Button type='primary'
                        onClick={handleSubmit}
                        disabled={loading}
                        className='footerBtnNavy'>{loading ? 'Loading...' : okBtnText}</Button>
                    ] : null

                  }
                >
                  {(currentAction === "Create Team" || currentAction === "Update Team") ?
                    renderTag(currentRecord) : <></>}
                </Modal>

                {
                  showDeleteAlert &&
                  <Alert message={TextMessage.TEAM_DELETE_TEXT} type="error"
                    showIcon closable onClose={handleDeleteAlertClose} icon={<DeleteOutlined />} className='alertStyle'
                  />
                }

                {
                  showTeamDeleteAlert &&
                  <Alert message={TextMessage.TEAM_DELETE_MESSAGE} type="error"
                    showIcon closable onClose={handleDeleteAlertClose} icon={<DeleteOutlined />} className='alertStyle'
                  />
                }

                {
                  showUpdateAlert &&
                  <Alert message={TextMessage.TEAM_UPDATE_TEXT} type={'success'}
                    showIcon closable onClose={handleUpdateAlertClose} className='alertStyle'
                  />
                }
                {
                  showAddAlert &&
                  <Alert message={TextMessage.TEAM_CREATE_TEXT} type={'success'}
                    showIcon closable onClose={handleAddAlertClose}
                    className='alertStyle'
                  />
                }
                {
                  showTeamDeleteErrorAlert &&
                  <Alert message={errorMessage} type={'error'}
                    showIcon closable onClose={handleDeleteAlertClose}
                    className='alertStyle'
                  />
                }
              </div>
            </Fullscreen>
          </>
      }
    </CommonLayout>
  )
}

export default Teams;