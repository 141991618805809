import { useState, useRef, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { createPromo, updateStage } from "actions/promotions/index";
import { Drawer, Button, Alert } from "antd";
import "./createPromoNew.css";
import { QuestionStatus, TextMessage } from "utils/constants";
import StatusDrawer from "components/common/statusDrawer";
import Teams from "./teams";
import CreateStaticForm from "./CreateStaticForm";
import SaticQuestionForm from "./staticQuestionForm";
import useAlert from "context/alert/alert.hook";
import { AlertVariant } from "context/alert/alert.provider";
import ReactHtmlParser from 'react-html-parser';
import PromoService from "services/promo-service/promo-service";
import useSWR, { mutate } from 'swr';

const CreatePromo = (props) => {
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [stages, setStages] = useState([]);
  const [isDraft, setIsDraft] = useState(false);
  const [staticQuestions, setStaticQuestions] = useState([]);
  const [showCreateAlert, setShowCreateAlert] = useState(false);
  const [createText, setCreateText] = useState("");
  const teamsRef = useRef();
  const formRef = useRef();
  const staticQuestionsRef = useRef();
  const dispatch = useDispatch();
  const [currentSuccessMsg, setCurrentSuccessMsg] = useState();
  const [promoTypeName, setPromoTypeName] = useState(null);
  const [promoId, setPromoId] = useState("");
  const { showAlert } = useAlert();
  const promoService = useMemo(() => new PromoService(), []);

  const handleSubmit = async (e) => {
    if (loading) return;

    const isFormValid = formRef.current.isValid();
    if (!isFormValid) return;

    if (e === QuestionStatus.Submitted) {
      if (!staticQuestionsRef.current.isValid() || !teamsRef.current.isValid()) return;
    }

    const payload = formRef.current.getFormData();
    payload.stages = teamsRef.current.getStagesData();
    payload.staticQuestions = staticQuestionsRef.current.getStaticQuestionData();

    if (e === QuestionStatus.Incomplete) {
      setLoadingSave(true);
      payload.isDraft = true;
      payload.actionTeam = stages[0].teamId;
      payload.actionTeamName = stages[0].name;
    } else if (e === QuestionStatus.Submitted) {
      setLoading(true);
      payload.actionTeam = stages[1] ? stages[1]?.teamId : stages[0].teamId;
      payload.actionTeamName = stages[1] ? stages[1]?.name : stages[0].name;
    }

    try {
      setLoadingSave(true);
      setLoading(true);
      const res = await dispatch(createPromo(payload));
      if (!res.error) {
        if (e === QuestionStatus.Incomplete) {
          setIsDraft(true);
          setCreateText("Promo has saved in draft successfully");
        }
        const promoId = res.payload?.data?.data._id;
        const promoName = formRef.current.getPromoTypeName();
        setPromoTypeName(promoName);
        let loading = false;
        if (res.payload?.data?.data.status === "Pending") {
          setPromoId(promoId);
          const pollUpdateStageAndStatus = async () => {
            try {
              setLoadingSave(true);
              setLoading(true);
              const response = await promoService.getPromoStatus(promoId);
              const status = response?.data?.status;
              if (status === "Success") {
                const updateStagePayload = {
                  teamId: stages[0].teamId,
                  status: QuestionStatus.Submitted,
                };
                await dispatch(updateStage(promoId, updateStagePayload));
                setLoadingSave(false);
                setLoading(false);
                loading = false;
                setShowCreateAlert(true);
                mutate({ key: "promoStatus", id: promoId });
                return;
              } else if (status === "Failed") {
                setLoadingSave(false);
                setLoading(false);
                loading = false;
                mutate({ key: "promoStatus", id: promoId });
                showAlert({
                  message: ReactHtmlParser(response.message),
                  variant: AlertVariant.ERROR,
                  duration: 0,
                });
                return;
              } else {
                // Continue polling
                setLoadingSave(true);
                setLoading(true);
                if (!loading) {
                  loading = true;
                  showAlert({
                    message: ReactHtmlParser(response.message),
                    variant: AlertVariant.INFO,
                    duration: 0,
                  });
                }
                setTimeout(pollUpdateStageAndStatus, 5000);
              }
            } catch (err) {
              setLoadingSave(false);
              setLoading(false);
              console.error("Error during stage/status polling:", err);
              showAlert({
                message: "An error occurred during submission.",
                variant: AlertVariant.ERROR,
                duration: 0,
              });
            }
          };

          pollUpdateStageAndStatus();
        } else {
          setLoadingSave(false);
          setLoading(false);
          // Handle non-pending status
          const updateStagePayload = {
            teamId: stages[0].teamId,
            status: QuestionStatus.Submitted,
          };
          setShowCreateAlert(true);
          await dispatch(updateStage(promoId, updateStagePayload));
          mutate({ key: "stageData", id: promoId }, (currentData) => ({
            ...currentData,
            teamId: updateStagePayload.teamId,
            status: updateStagePayload.status,
          }));
        }
      } else {
        setLoadingSave(false);
        setLoading(false);
        showAlert({
          message: ReactHtmlParser(res?.error?.response?.data?.message),
          variant: AlertVariant.ERROR,
          duration: 0,
        });
      }
    } catch (error) {
      console.error("Error submitting promo:", error);
    } finally {
      setLoading(false);
      setLoadingSave(false);
    }
  };

  const updateStages = useCallback((stages) => {
    setStages(stages);
  }, []);

  const updateStaticQuestion = useCallback((questions) => {
    setStaticQuestions(questions);
  }, [])

  const showErrorMsg = useCallback((msg) => {
    setCurrentSuccessMsg({
      message: msg,
      type: "error",
    });
    setTimeout(() => {
      setCurrentSuccessMsg("");
    }, 6000);
  }, []);

  const nextTeamName = stages?.[1]?.name;
  return (
    <div>
      <Drawer
        title={`Create a New Offer`}
        width={640}
        height={1900}
        onClose={() => {
          if (!(loading || loadingSave)) {
            props.onClose();
          }
        }}
        open={props.visible}
        bodyStyle={{
          paddingBottom: 80,
        }}
        maskClosable={!(loading || loadingSave)}
        footer={
          <div className="flex space-between w-full create-footer">
            <Button
              type="link"
              onClick={() => {
                if (!loading) handleSubmit(QuestionStatus.Incomplete);
              }}
              disabled={loading || loadingSave}
            >
              {loadingSave ? `Loading` : "Save as draft"}
            </Button>
            <div>
              <Button
                onClick={() => {
                  if (!loading && !loadingSave) props.onClose();
                }}
                disabled={loading || loadingSave}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  if (!loading) handleSubmit(QuestionStatus.Submitted);
                }}
                disabled={loading || loadingSave}
                className="next-btn"
              >
                {loading ? `Loading` : "Submit"}
              </Button>
            </div>
          </div>
        }
      >
        {currentSuccessMsg && (
          <Alert
            message={currentSuccessMsg.message}
            closable
            type={currentSuccessMsg.type}
            showIcon
          />
        )}

        <CreateStaticForm
          ref={formRef}
          setStages={updateStages}
          errormsg={showErrorMsg}
          setStaticQuestions={updateStaticQuestion}
          type="create"
        />

        <div style={{ marginTop: '15px' }}>
          <SaticQuestionForm
            ref={staticQuestionsRef}
            questions={staticQuestions}
            action="create"
            errormsg={(msg) => {
              setCurrentSuccessMsg({
                message: msg,
                type: "error",
              });
              setTimeout(() => {
                setCurrentSuccessMsg("");
              }, 6000);
            }} />
        </div>

        <Teams
          stages={stages}
          ref={teamsRef}
          action="create"
          errormsg={(msg) => {
            setCurrentSuccessMsg({
              message: msg,
              type: "error",
            });
            setTimeout(() => {
              setCurrentSuccessMsg("");
            }, 6000);
          }}
        />
      </Drawer>
      {showCreateAlert && (
        promoTypeName.includes("Funded") &&
          !promoTypeName.includes("Not Funded") &&
          !promoTypeName.includes("ECOM") && !isDraft ? (
          <StatusDrawer
            onClose={() => {
              setShowCreateAlert(false);
              setCreateText(false);
              props.onClose({ refresh: true });
            }}
            title={<span style={{ fontSize: 13 }}>

              Creating a corresponding Funding Agreement is required to ensure promotion is Vendor Funded.
            </span>}
            visible={showCreateAlert}
            subtitle={
              <span style={{ marginTop: 20, fontSize: 10, fontWeight: 'bold' }}>
                Please proceed to{' '}
                <a href={process.env.REACT_APP_VENDOR_CONTRACTS_MANAGEMENT_URL} target="_blank" rel="noopener noreferrer">
                  Vendor Contracts Management
                </a>
              </span>
            }
          />
        ) : (
          <StatusDrawer
            onClose={() => {
              setShowCreateAlert(false);
              setCreateText(false);
              props.onClose({ refresh: true });
            }}
            title={createText || TextMessage.PROMOTION_CREATE_TEXT}
            visible={showCreateAlert}
            subtitle={
              !isDraft
                ? nextTeamName
                  ? `It has been submitted to ${nextTeamName} for action.`
                  : "You can check at 'All promo'"
                : null
            }
          />
        )
      )}
    </div>
  );
};

export default CreatePromo;
