import { createContext, useMemo, useCallback, useEffect } from "react";
import { notification } from "antd";
import { ArgsProps } from "antd/lib/notification";
import { useSelector } from "react-redux";
import { showMessage } from "actions/common";

export enum AlertVariant {
  ERROR = "error",
  INFO = "info",
  SUCCESS = "success",
  WARNING = "warning",
}

export interface AlertProps extends ArgsProps {
  variant: `${AlertVariant}`;
}

const defaultAlertProps: AlertProps = {
  duration: 4,
  maxCount: 5,
  message: "",
  variant: AlertVariant.INFO,
};

export type AlertContextProps = {
  showAlert: (toast: AlertProps) => void;
};

export const AlertContext = createContext<AlertContextProps>({
  showAlert: () => {},
});

const AlertProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [api, contextHolder] = notification.useNotification();
  const message = useSelector((state: any) => state.common?.message);

  const showAlert = useCallback(
    (args: AlertProps) => {
      const argsWithDefault = { ...defaultAlertProps, ...args, duration: args.duration ?? defaultAlertProps.duration };
      const { variant, ...rest } = argsWithDefault;
      api[variant]({
        ...rest,
      });
    },
    [api]
  );

  const contextValue: AlertContextProps = useMemo(
    () => ({
      showAlert,
    }),
    [showAlert]
  );

  useEffect(() => {
    if (message) {
      showAlert({ message, variant: AlertVariant.ERROR });
    }
    setTimeout(() => {
      if (message) {
        showMessage("");
      }
    }, 4000);
  }, [message, showAlert]);

  return (
    <AlertContext.Provider value={contextValue}>
      {children}
      {contextHolder}
    </AlertContext.Provider>
  );
};

export default AlertProvider;
