import "./promoStatusTable.css";
import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Fullscreen from "fullscreen-react";
import { Spin, Switch, Input, Modal, Tag, Button } from "antd";
import {
  ColumnHeightOutlined,
  ExclamationCircleOutlined,
  ExportOutlined,
  FullscreenOutlined,
  LoadingOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import CustomTable from "components/common/table/TableNew";
import StatusDrawer from "components/common/statusDrawer";
import CommonLayout from "components/layout/Layout";
import ViewPromo from "./ViewPromoNew";
import EditPromo from "./EditPromoNew";
import { Outlet } from "react-router-dom";
import { PROMO_COLUMNS } from "utils/column";
import * as Constants from "utils/constants";
import { useParams } from "react-router";
import {
  getPromoByStatus,
  deletePromo,
  changeStatusPromo,
  getPromoExportData,
} from "actions/promotions/index";
import { TextMessage, DATE_FORMAT_DASH } from "utils/constants";
import session from "utils/session";
import moment from "moment";


const { Search } = Input;

const PromoStatusTable = () => {
  const dispatch = useDispatch();
  // const { promoByStatus, promoByStatusTotal } = useSelector((state) => state.promo);
  const [promoByStatus, setPromoByStatus] = useState([]);
  const [promoByStatusTotal, setPromoByStatusTotal] = useState(0);
  const { id } = useParams();
  const [tableSize, setTableSize] = useState("default");
  const [tableClicked, setTableClicked] = useState(0);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [editId, setEditId] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [viewId, setViewId] = useState("");
  const [viewModal, setViewModal] = useState(false);
  const [searchedPromo, setSearchedPromo] = useState(null);
  const [live, setLive] = useState(false)
  const [isEnter, setIsEnter] = useState(false);
  const [currPage, setCurrPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [currentFilters, setCurrentFilters] = useState({});
  const [userActive, setUserActive] = useState(true);
  const [userDeActive, setUserDeActive] = useState(false);
  const [reloadingState, setReLoadingState] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [tableLoading, setTableLoading] = useState(false);

  useEffect(() => {
    const getCall = async () => {
      setLoadingState(true);
      const res = await dispatch(getPromoByStatus("status", id, currPage, pageSize));
      if (res) {
        setPromoByStatus(res?.payload?.data?.data?.promoList);
        setPromoByStatusTotal(res?.payload?.data?.data?.pagination?.total);
        setLoadingState(false);
      }
    };
    getCall();
  }, [dispatch, id]);

  const onSearch = async (value) => {
    setSearchedPromo(
      promoByStatus.filter((item) =>
        item.name.toLowerCase().trim().includes(value.toLowerCase().trim())
      )
    );
  };

  const handleTableHeight = () => {
    if (tableClicked === 2) {
      setTableClicked(0);
      setTableSize("default");
    }
    if (tableClicked === 0) {
      setTableClicked(tableClicked + 1);
      setTableSize("small");
    }
    if (tableClicked === 1) {
      setTableClicked(tableClicked + 1);
      setTableSize("middle");
    }
  };


  const deletePromotion = (val) => {
    Modal.confirm({
      title: TextMessage.CONFIRMATION_TEXT,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: () => deletePromoYes(val),
      okType: "primary",
    });
  };

  const deletePromoYes = async (val) => {
    await dispatch(deletePromo(val._id));

    setShowDeleteAlert(true);
    dispatch(getPromoByStatus("status", id));
  };

  const handleDeleteAlertClose = () => {
    setShowDeleteAlert(false);
  };

  const setupRows = (promoByStatus) => {
    return promoByStatus?.map((o) => {
      const oStatus =
        (o.status === "Submitted" && <Tag color={"red"}>Submitted</Tag>) ||
        (o.status === "Pending" && <Tag color={"red"}>Pending</Tag>) ||
        (o.status === "Canceled" && <Tag color={"red"}>Cancelled</Tag>) ||
        (o.status === "Expired" && (
          <Tag className="status-symbol-expired">Expired</Tag>
        )) ||
        (o.status === "Incomplete" && (
          <Tag className="status-symbol-incomplete">Incomplete</Tag>
        )) ||
        (o.status === "Approved" && (
          <Tag className="status-symbol-approved">Approved</Tag>
        )) ||
        (o.status === "Live" && <Tag className="status-symbol-live">Live</Tag>) || 
        (o.status === "Rejected" && <Tag className="status-symbol-rejected">Rejected</Tag>) ||
        (o.status === Constants.LIVE_ERRORS && (
          <Tag className="status-symbol-lv">{Constants.LIVE_ERRORS}</Tag>
        )) ||
        (o.status === Constants.PENDING_ERRORS && (
          <Tag className="status-symbol-pv">{Constants.PENDING_ERRORS}</Tag>
        )) ||
        (o.status === Constants.APPROVED_ERRORS && (
          <Tag className="status-symbol-av">{Constants.APPROVED_ERRORS}</Tag>
        )) ||
        (o.status === Constants.EXPIRED_ERRORS && (
          <Tag className="status-symbol-pv">{Constants.EXPIRED_ERRORS}</Tag>
        )) ||
        (o.status === Constants.INCOMPLETE_ERRORS && (
          <Tag className="status-symbol-iv">{Constants.INCOMPLETE_ERRORS}</Tag>
        )) ||
        (o.status === Constants.SUBMITTED_ERRORS && (
          <Tag className="status-symbol-iv">{Constants.SUBMITTED_ERRORS}</Tag>
        ))
      const promoCreatedBy = (o?.owner?._id && o?.owner?._id === session.userId) ? true : false
      return {
        ...o,
        name: o.name,
        _id: o._id,
        redemptiontype: o.type,
        status: oStatus,
        eventName: o?.event?.name ?? "-",
        eventId: o?.event?.eventId ?? "-",
        workFlowType: o?.isEmergency ? "Emergency" : "Standard",       
        promoStatus: o.status,
        submittedby: o.owner?.fullName,
        startDate: o?.event?.startDate
            ? moment(o?.event?.startDate).utc().format(DATE_FORMAT_DASH)
            : "-",
          endDate: o?.event?.endDate
            ? moment(o?.event?.endDate).utc().format(DATE_FORMAT_DASH)
            : "-",
        pricingNeeded: o.pricingNeeded || '',
        promoTag: o.promoTag || '',
        active: o.isActive ? (
          <Switch
            checked={userActive}
            className="switchStyle"
            onChange={() => changeStatusYes(o._id)}
            disabled={o.status === "Live"}
          />
        ) : (
          <Switch
            checked={userDeActive}
            onChange={() => changeStatusNo(o._id)}
            disabled={o.status === "Live"}
          />
        ),
        promoCreatedBy: promoCreatedBy,
        isPromo: true,
        isEditable: o.promoStatus !== 'Canceled'
      };
    });
  };

  const handleCreateClose = () => {
    setViewModal(false);
    setEditModal(false);
  };

  const openPromo = (record, name) => {
    if (name === "view") {
      setViewModal(true);
      setViewId(record?._id);
      setLive(false);
    }
    if (name === "edit" && record.promoStatus === "Live") {
      setViewModal(true);
      setViewId(record?._id);
      setLive(true);
    }
    if (name === "edit" && record.promoStatus !== "Live") {
      setEditModal(true);
      setEditId(record?._id);
      setLive(false);
    }
  }

  const changeStatusYes = async (id) => {
    Modal.confirm({
      title: "Are you sure you want to deactivate the promo?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: () => userDeactivateYes(id, false),
      okCancel: userDeactivateNo,
      okType: "primary",
    });
  };

  const userDeactivateYes = async (id1, checked) => {
    const response = await dispatch(changeStatusPromo(id1, { isActive: checked }));
    if (response) {
      await dispatch(getPromoByStatus("status", id));
    }
  };

  const userDeactivateNo = () => {
    setUserActive(true);
  };

  const changeStatusNo = async (id, checked) => {
    Modal.confirm({
      title: "Do you want to activate the promo?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: () => userActivateYes(id, true),
      okCancel: userActivateNo,
      okType: "primary",
    });
  };

  const userActivateYes = async (id1, checked) => {
    const response = await dispatch(changeStatusPromo(id1, { isActive: checked }));
    if (response) {
      await dispatch(getPromoByStatus("status", id));
    }
  };
  const userActivateNo = () => {
    setUserDeActive(false);
  };

  const handleReload = async () => {
    setReLoadingState(true)
    const res = await dispatch(getPromoByStatus("status", id));
    if (res) {
      setReLoadingState(false)
    }
  }

  const exportPromoData = async () => {
    setButtonLoading(true)
    const res = await dispatch(getPromoExportData(id))

    if (res.error === undefined) {
      const url = window.URL.createObjectURL(
        new Blob([res.payload.data]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${id}_Promo_Data.csv`,
      );

      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      setButtonLoading(false)
    } else {
      setButtonLoading(false)
    }
  }

  return (
    <CommonLayout
      className="content-mr"
      backButton={true}
      id={id}
      backUrl={"/promotions-v2"}
      breadCrumbText="Promo Management"
    >
      {(loadingState || reloadingState) ?
        <div className="errorTipDiv">
          <Spin size="middle" tip="Loading..." />
        </div>
        :
        <Fullscreen isEnter={isEnter} onChange={setIsEnter}>
          <div className="user-table-card">
            <div className="table-topbar">
              <div className="shelf-left-content">
                <Search
                  className="userBtn"
                  enterButton
                  allowClear
                  placeholder="Search By Offer Name"              
                  onSearch={onSearch}
                />
              </div>


              <div className="filterGrpWrapper1">
                <Button icon={buttonLoading ? <LoadingOutlined /> : <ExportOutlined />} onClick={exportPromoData} disabled={buttonLoading}>Export</Button>

                <div className="settingGrp">
                  <ReloadOutlined onClick={handleReload} className='reloadIconStyle' />
                  <ColumnHeightOutlined
                    className="reloadIconStyle"
                    onClick={handleTableHeight}
                  />
                  <FullscreenOutlined
                    onClick={() => {
                      setIsEnter(true);
                    }}
                    className="reloadIconStyle"
                  />
                </div>
              </div>
            </div>

            <Outlet />

            {/* table starts */}

            <div className="w-100 mt-16">
              <CustomTable
                className="assign"
                columns={PROMO_COLUMNS}
                rows={
                  searchedPromo
                    ? setupRows(searchedPromo)
                    : setupRows(promoByStatus)
                }
                rowKey="_id"
                isViewable={true}
                isEditable={!(id === 'Canceled' || id === 'Expired')}
                onView={(record) => openPromo(record, "view")}
                onEdit={(record) => openPromo(record, "edit")}
                isDeleteable={true}
                onDelete={deletePromotion}status 
                total={promoByStatusTotal}
                size={tableSize}
                isPopup={true}
                isLoading={tableLoading}
                onChange={async (
                  { current, pageSize },
                  filter = {},
                  { field, order, columnKey }
                ) => {
                  const filters= {};
                  const keys = Object.keys(filter);
                  keys.forEach((key) => {
                    if (filter[key]) {                      
                      if (key === "active") {
                        filters["isActive"] =
                          filter[key]?.join(",");
                      } else if (key === "status") {
                        filters[key] = filter[key]?.join(",");
                      }else if (key === "isEmergency") {
                        filters[key] = filter[key]?.length ? filter[key].join(",") : filter[key];
                      }
                    }
                  });
                  console.log(filters);
                  setCurrentFilters(filters);
                  setTableLoading(true);
                  setCurrPage(current);
                  setPageSize(pageSize);
                  const res = await dispatch(
                    getPromoByStatus("status", id, current, pageSize, columnKey, order, filters)
                  );
                  if (res) {
                    setPromoByStatus(res?.payload?.data?.data?.promoList);
                    setPromoByStatusTotal(res?.payload?.data?.data?.pagination?.total);
                    setLoadingState(false);
                    setTableLoading(false);
                  }
                }}
              />
              {showDeleteAlert && (
                <StatusDrawer
                  onClose={handleDeleteAlertClose}
                  title={TextMessage.PROMOTION_DELETE_TEXT}
                  visible={showDeleteAlert}
                />
              )}

              {viewModal && (
                <ViewPromo
                  visible={viewModal}
                  viewId={viewId}
                  onClose={handleCreateClose}
                  statusId={id}
                  liveFlag={live}
                  data={{ _id: viewId }}
                />
              )}
              {editModal && (
                <EditPromo
                  visible={editModal}
                  editId={editId}
                  onClose={handleCreateClose}
                  statusId={id}
                />
              )}
            </div>
          </div>
        </Fullscreen>
      }

    </CommonLayout>
  );
};

export default PromoStatusTable;