import CommonLayout from "components/layout/Layout";
import React, { useState, useMemo, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Input, Button, Select, Row, Col, Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import AutomationService from "services/automation-templete/automation.service";
import useAlert from "context/alert/alert.hook";
import { AlertVariant } from "context/alert/alert.provider";
import "./../automation-template.css";
import XmlEditor from "components/xml-editor/xml-editor.component";
import useSWR from "swr";
import { useFormik } from "formik";
import { CreateXMLTemplate } from "common/schemas.yup";
import { replaceBracesWithSpace } from "common/helper";

const { Dragger } = Upload;
const { Option } = Select;

const CreateTemplate = () => {
  const automationService = useMemo(() => new AutomationService(), []);
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const params = useParams();
  const [actionType, setActionType] = useState("");
  const [state, setState] = useState({
    fileName: "",
    initText: "",
  });
  const [xmlData, setXmlData] = useState(null); // Initial XML data

  const { data: xmlInfo } = useSWR(
    { key: "xml-by-Id", ...params },
    (payload) =>
      params?.id ? automationService.getXMLTemplateById(payload.id) : undefined
  );

  const {
    values,
    errors,
    setValues,
    setFieldValue,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      templateName: "",
      promoType: "",
      fileName: ""
    },
    validationSchema: CreateXMLTemplate,
    onSubmit: async (formVal) => {
      if (params?.type === "edit") {
        const respXml = await automationService.updateXMLTemplate(params?.id, {
          name: formVal.templateName,
          fileName: state.fileName,
          promoTypeId: formVal.promoType,
          templateSchema: xmlData,
        });
        if (respXml?.status) {
          showAlert(respXml?.message, AlertVariant.SUCCESS);
          handleCancel();
        }
      } else {
        const respXml = await automationService.createXMLTemplate({
          name: formVal.templateName,
          fileName: state.fileName,
          promoTypeId: formVal.promoType,
          templateSchema: replaceBracesWithSpace(xmlData),
        });
        if (respXml?.status) {
          showAlert(respXml?.message, AlertVariant.SUCCESS);
          handleCancel();
        }
      }
    },
  });

  useEffect(() => {
    setActionType(params?.type);
  }, [params?.type]);

  useEffect(() => {
    if (xmlInfo?.status) {
      const xml = xmlInfo?.message?.data;
      setValues({
        templateName: xml?.name ?? "",
        promoType: xml?.promoTypeId?._id ?? "",
        fileName: xml?.fileName ?? ""
      });
      setXmlData(xml.templateSchema);
      setState({
        fileName: xml.fileName,
        initText: xml.templateSchema,
      });
    }
  }, [xmlInfo, setValues]);

  useEffect(() => {
    setXmlData(state.initText);
  }, [values?.promoType, state.initText]);

  // const handleFileUpload = (event) => {
  //   const file = event.file;
  //   const isXML = file.type === "text/xml";
  //   if (!isXML) {
  //     message.error("You can only upload XML file!");
  //     return;
  //   }
  //   const reader = new FileReader();
  //   reader.onload = (e) => {
  //     const text = e.target.result;
  //     if (text) {
  //       setState({ ...state, fileName: file.name, initText: text });
  //       setXmlData(text);
  //     }
  //   };
  //   reader.readAsText(file);
  // };

  const handleFileUpload = (event) => {
    const file = event.file;
    const isXML = file.type === "text/xml";
    if (!isXML) {
      message.error("You can only upload XML file!");
      return;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target.result;
      if (text) {
        setState({ ...state, fileName: file.name, initText: text });
        setXmlData(text);
        // Clear fileName validation error
        setFieldValue('fileName', file.name); // Assuming you reset it here if it's tied to fileName validation
      }
    };
    reader.readAsText(file);
  };

  const handleCancel = () => {
    navigate(`/xml-templates`);
  };

  const { data } = useSWR({ key: "promo-type-list" }, () =>
    automationService.getPromoType()
  );

  const promoList = data?.data?.payload?.promoTypes;
  const { data: promoQuestions } = useSWR(
    { key: "promo-type-questions", promoType: values?.promoType },
    (payload) =>
      values?.promoType
        ? automationService.getPromoQuestions(values?.promoType)
        : undefined
  );

  const questionsList = useMemo(
    () =>
      promoQuestions?.data?.map((item) => ({
        value: item,
        label: item,
      })),
    [promoQuestions]
  );

  return (
    <CommonLayout
      className="content-mr"
      breadCrumbText="Automation Template / XML Template"
      backButton={true}
      backUrl={"/xml-templates"}
    >

      <>
        <form onSubmit={handleSubmit}>
          <div className="template_body">
            <div className="template_header">
              {actionType === "view"
                ? "Template Details"
                : actionType === "edit"
                  ? "Update XML Template"
                  : "Create XML Template"}
            </div>
            <Row gutter={16} className="mb-25" style={{ width: "100%" }}>
              <Col span={12}>
                <div>
                  <label>
                    {actionType !== "view" && (
                      <span className="spanReq">*</span>
                    )}{" "}
                    Template Name
                  </label>
                  <Input
                    placeholder="Write here"
                    value={values.templateName}
                    className="mt-5 full-width"
                    onChange={handleChange}
                    name="templateName"
                    id="templateName"
                    disabled={actionType === "view"}
                  />
                  <span className="errorLabel">{errors.templateName}</span>
                </div>
              </Col>
              <Col span={12}>
                <div>
                  <label>
                    {actionType === "create" && (
                      <span className="spanReq">*</span>
                    )}{" "}
                    Promo Type
                  </label>
                  <Select
                    allowClear
                    style={{
                      width: "100%",
                      padding: "5px 0 0 0",
                    }}
                    id="promoType"
                    placeholder="Search and select Promo Type"
                    onChange={(value) => setFieldValue("promoType", value)}
                    value={values.promoType}
                    showSearch
                    disabled={actionType === "view"}
                    filterOption={(input, option) => {
                      return (option?.children?.toLowerCase() ?? "").includes(
                        input?.toLowerCase()
                      );
                    }}
                  >
                    {promoList?.map((option) => (
                      <Option key={option._id} value={option._id} data={option}>
                        {option.promoType}
                      </Option>
                    ))}
                  </Select>
                  <span className="errorLabel">{errors.promoType}</span>
                </div>
              </Col>
            </Row>
            <Row gutter={16} className="mb-25" style={{ width: "100%" }}>
              <div style={{ padding: "0 0 0 5px", width: "100%" }}>
                <label>
                  {actionType !== "view" && <span className="spanReq">*</span>}{" "}
                  Upload Template
                </label>
                <div className="upload-template">
                  {xmlData ? (
                    <XmlEditor
                      xmlData={xmlData}
                      optionsVal={questionsList}
                      setXmlData={setXmlData}
                      promoType={values.promoType}
                      actionType={params?.type}
                    />
                  ) : (
                    <Dragger
                      onChange={handleFileUpload}
                      beforeUpload={() => false}
                      multiple={false}
                      showUploadList={false}
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Click to Upload Template
                      </p>
                    </Dragger>
                  )}
                </div>
                <span className="errorLabel">{errors.fileName}</span>
              </div>
            </Row>

            <div className="button_div">
              <Button onClick={handleCancel}>Cancel</Button>
              {params.type !== "view" && (
                <Button
                  type="submit"
                  onClick={handleSubmit}
                  className="next-btn"
                >
                  Save
                </Button>
              )}
            </div>
          </div>
        </form>
      </>
    </CommonLayout>
  );
};

export default CreateTemplate;
