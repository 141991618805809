import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { Drawer, Button, Radio, Spin } from "antd";
import "./createPromoNew.css";
import ViewActivity from "./ViewActivity";
import StatusDrawer from "components/common/statusDrawer";
import CreateStaticForm from "./CreateStaticForm";
import Teams from "./teams";
import RejectionModal from "./RejectionModal";
import SaticQuestionForm from "./staticQuestionForm";
import PromoService from "services/promo-service/promo-service";

const EditPromo = (props) => {
  const [stages, setStages] = useState([]);
  const [mode, setMode] = useState("promo");
  const [loadingState] = useState(false);
  const [showCreateAlert, setShowCreateAlert] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [staticQuestions, setStaticQuestions] = useState([]);
  const formRef = useRef();
  const teamsRef = useRef();
  const staticQuestionsRef = useRef();
  const promoService = useMemo(() => new PromoService(), []);
  const [promoDetail, setpromoDetails] = useState({})

  useEffect(() => {
    if (props?.data) {
      if (props?.data?.stages) {
        setStages(props.data.stages);
      }
      if (props?.data?.staticQuestions) {
        setStaticQuestions(props.data.staticQuestions)
      }
    } else {
      getPromoDetails(props?.viewId);
    }
  }, [props?.data]);

  const getPromoDetails = async (promoId = "") => {
    await promoService.getPromoDetailsById(promoId).then((resp) => {
      setpromoDetails(resp?.data);

      if (resp?.data?.stages) {
        setStages(resp?.data?.stages);
      }
      if (resp?.data?.staticQuestions) {
        setStaticQuestions(resp?.data?.staticQuestions);
      }
    })
      .catch((error) => console.error("Error fetching promo details:", error));
  }

  const handleModeChange = (e) => {
    setMode(e.target.value);
  };

  const updateStages = useCallback((stages) => {
    // setStages(stages);
  }, []);
  const updateStaticQuestion = useCallback((questions) => {
    // setStaticQuestions(questions);
  }, [])
  const renderFooter = () => {
    return (
      <>
        <Button type="link" onClick={() => props.onClose()}>
          Cancel
        </Button>
        <div></div>
      </>
    );
  };
  const footer = () => {
    return (
      <div className="flex space-between w-full create-footer">
        {renderFooter()}
      </div>
    );
  };
  return (
    <div>
      <Drawer
        title={`View Promo`}
        width={640}
        height={1900}
        onClose={props.onClose}
        open={props.visible}
        bodyStyle={{
          paddingBottom: 80,
        }}
        footer={footer()}
      >
        <Radio.Group
          onChange={handleModeChange}
          value={mode}
          style={{
            marginBottom: 8,
            width: "100%",
            display: "flex",
          }}
        >
          <Radio.Button value="promo" style={{ width: "100%" }}>
            Promo
          </Radio.Button>
          <Radio.Button value="activity" style={{ width: "100%" }}>
            Activity log
          </Radio.Button>
        </Radio.Group>
        {!loadingState && mode === "promo" && (
          <>
            <CreateStaticForm
              ref={formRef}
              setStages={updateStages}
              data={props.data ? props.data : promoDetail}
              disabled={true}
            />
            <div style={{ marginTop: '15px' }}>
              <SaticQuestionForm
                ref={staticQuestionsRef}
                questions={staticQuestions}
                action="view"
              />
            </div>

            <Teams
              stages={stages}
              ref={teamsRef}
              action={"view"}
            //   teamClick={(index) => setCurrentStage(index)}
            />
          </>
        )}
        {loadingState && (
          <div className="errorTipDiv">
            <Spin size="middle" tip="Loading..." />
          </div>
        )}

        {!loadingState && mode === "activity" && (
          <ViewActivity id={props?.data?._id} />
        )}
      </Drawer>
      <RejectionModal
        showModal={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        showAlert={() => {
          setShowCreateAlert(
            "The rejection comment have been successfully added!"
          );
        }}
        stages={stages}
      />
      {showCreateAlert && (
        <StatusDrawer
          onClose={() => {
            setShowCreateAlert(false);
            props.onClose({ refresh: true });
          }}
          title={showCreateAlert}
          visible={showCreateAlert}
          subtitle="You can check at “All promo”"
        />
      )}
    </div>
  );
};

export default EditPromo;
